<template>
  <div>
    <!-- Entity Handler -->
    <EntityHandlerSidebar
      v-if="tableReady && !hideEdit && !reportView && !mapView"
      v-model="isEntityHandlerSidebarActive"
      :module="config.module"
      :entity="entity"
      :duplicate="isEntityDuplicate"
      :clear-entity-data="clearEntityData"
      :add-entity="addEntity"
      :update-entity="updateEntity"
      :form-fields="formFields"
      :size="sidebarSize"
      :import-csv="importCsv"
    />
    <!-- Filters -->
    <b-card-actions
      v-if="filters"
      :title="$t('Filters')"
      :collapsed="!config.filtersOpen"
      :class="{
        'mb-1': config.limited,
      }"
      action-collapse
      no-body
    >
      <b-card-body>
        <b-row>
          <b-col
            v-for="(filter, index) in filters"
            :key="filter.name"
            cols="12"
            md="4"
            class="mb-1"
          >
            <label>{{ filter.label }}</label>
            <div v-if="filter.type === 'date'" class="position-relative">
              <flat-pickr
                v-model="filterVals[index]"
                :config="{
                  dateFormat: 'Y-m-d',
                  minDate: filter.minDateFieldIdx
                    ? filterVals[filter.minDateFieldIdx]
                    : null,
                  maxDate:
                    filter.maxDateFieldIdx && filterVals[filter.maxDateFieldIdx]
                      ? filterVals[filter.maxDateFieldIdx]
                      : filter.maxDate
                      ? filter.maxDate
                      : null,
                  allowInput: false,
                }"
                :placeholder="$t('YYYY-MM-DD')"
                class="form-control flat-pickr-inherit"
                @on-change="updateFilterValues($event, index)"
              />
              <span
                v-if="filterVals[index]"
                class="flat-pickr-clear"
                @click="updateFilterValues(null, index)"
              >
                x
              </span>
            </div>
            <v-select
              v-else-if="filter.type === 'boolean'"
              :value="filterVals[index]"
              :options="getBooleanOptions"
              :reduce="(val) => val.id"
              label="name"
              class="w-100"
              append-to-body
              clearable
              @input="updateFilterValues($event, index)"
            />
            <v-select
              v-else-if="filter.options"
              :value="filterVals[index]"
              :options="filter.options"
              :reduce="(val) => val.id"
              :clearable="!filter.value"
              label="title"
              class="w-100"
              filterable
              append-to-body
              @input="updateFilterValues($event, index)"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card-actions>

    <b-alert
      v-if="config.limited"
      :variant="remainingEntries < 1 ? 'danger' : 'secondary'"
      show
      class="p-1"
    >
      {{
        $t('RemainingEntries', {
          remaining: remainingEntries,
          limit: config.limited,
        })
      }}
   
      <button v-if="isSeller" class="csvfile btn w-100 btn-primary" @click="openModal">{{ $t('CSV Upload') }}</button>
     
      <div v-if="modalOpen" id="csvModel" class="modal">
        <div class="modal-content">
          <!--<span class="close" @click="closeModal">&times;</span>-->
          <h2>{{ $t('Upload CSV/Excel') }}</h2>
          
          <!-- File input for multi-file selection -->
          <!-- Loader -->
        <div v-if="loading" class="loader">&nbsp;</div>
        <!--<div v-if="!loading && uploadresponse">
          <p style="text-align:center;margin:40px auto;font-size:20px;color:#1f1f1f;">{{ uploadresponse }}</p>
        </div>-->
          <div v-if="!loading && !uploadresponse" class="browsetext">
            <label for="fileInput">
              <b-avatar v-show="loading !== 1" size="60">
                <feather-icon icon="UploadIcon" size="30" />
              </b-avatar>
              <div>{{ $t('Drag and drop CSV/Excel containing all the Product Details') }}</div>
              <div>{{ $t('Max file size: 15MB') }}</div>
              <a class="bclor">{{ $t('Browse') }}</a>
              <input id="fileInput" ref="fileInput" type="file" style="display:none" @change="handleFileChange" />
            </label>
          </div>

          <!-- List of uploaded files -->
          <ul v-if="uploadedFiles.length > 0 && !loading && !uploadresponse">
            <li v-for="(file, index) in uploadedFiles" :key="index">
              <span class="filenam">{{ file.name }} </span> - <span class="filesize">{{ file.size }} bytes </span>
            </li>
          </ul>
          <div v-if="!loading && !uploadresponse">
            <!--<p style="text-align:center;">{{ uploadresponse }}</p>-->
            <button class="fileUploads btn btn-primary" @click="uploadFiles">{{ $t('Upload') }}</button>
            <button class="clear cls btn btn-primary" @click="closeModal"> {{ $t('Cancel') }}</button>
          </div>
       


         <!-- Mapping Section -->
         
<div v-if="uploadSuccess" class="mapping-section" style="border: 2px solid #ccc;padding: 13px;border-radius: 10px;">

  <p v-if="unmappedCategories.length === 0" style="font-size:13px;text-align:center;">
    {{ $t('There is no mapping categories. So please click the Upload button.') }}
  </p>
  
  <p v-else style="font-size:13px;text-align:center;color:#b4b7bd;">
    {{ $t('Please map the MyPlaceUp product categories to the corresponding categories of the file that you uploaded so that your products fall under the right categories.') }}
  </p>
  
  <h2 v-if="unmappedCategories.length > 0">{{ $t('Category Mapping') }}</h2>

  <div 
    v-for="(item, index) in mappings" 
    :key="index" 
    style="display: flex; align-items: center; margin-bottom: 10px;">

    <!-- Wrapping both Category and Subcategory columns in one border -->
    <div style="flex: 2; border: 2px solid #ccc; padding: 8px; border-radius: 5px; display: flex; gap: 10px;">
      
      <!-- Category column -->
      <div style="flex: 1;">
        <label>{{ $t('MyPanel Category') }}</label>
        <select 
          v-model="item.selected" 
          class="dropdown"
          style="width: 100%;" 
          @change="fetchSubcategories(item.selected, index)">
          <option disabled value="">Select a category</option>
          <option 
            v-for="option in categories" 
            :key="option.id" 
            :value="option.id">{{ option.label }}
          </option>
        </select>
      </div>

      <!-- Subcategory column -->
      <div style="flex: 1;">
        <label>{{ $t('MyPanel Sub Category') }}</label>
        <select 
          v-model="item.subcategory" 
          class="dropdown" 
          style="width: 100%;">
          <option value="">{{ $t('No subcategory') }}</option> <!-- Allow unselected option -->
          <option 
            v-for="option in subcategories[index]" 
            :key="option.id" 
            :value="option.id">{{ option.title }}
          </option>
        </select>
      </div>
      
    </div>
    <div style="align-self: center; font-size: 18px; padding: 0 5px;color:#b4b7bd;">&#8594;</div> <!-- Right arrow -->

    <!-- Input column -->
    <div style="flex: 2; border: 2px solid #ccc; padding: 8px; border-radius: 5px; display: flex; gap: 10px;">
      <div style="flex: 1; margin-left: 10px;">
        <label>{{ $t('Imported Categories from CSV') }}</label>
        <input 
          v-model="item.input" 
          :disabled="true"
          type="text"
          placeholder="Enter value" 
          class="input-box"
          style="width: 100%;" />
      </div>
    </div>

    <!-- Remove mapping -->
    <button 
      class="delete-icon" 
      style="background: none; border: none; cursor: pointer;" 
      @click="deselectMapping(index)">❌
    </button>
  </div>

  <!-- Import Button -->
  <div class="catmap">
    <button 
      :disabled="!canImport || isLoading"  
      class="import-button" 
      style="margin-top: 20px; padding: 10px 15px; float:right; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;" 
      @click="importSelectedCategories">
      <span v-if="isLoading">
        <i class="fa fa-spinner fa-spin" /> {{ unmappedCategories.length === 0 ? $t('Upload') : $t('Map Categories') }}
       
      </span>
      <span v-else>
        {{ unmappedCategories.length === 0 ? $t('Upload') : $t('Map Categories') }}
        
      </span>
    </button>
    <button
    style="margin-top: 20px; padding: 13px !important;"
    class="clear cls btn btn-primary"
    @click="deleteAndCloseModal">
    {{ $t('Cancel') }}
</button>
  </div>
  <span v-if="showError && !item.selected" class="error-message" style="color:red">{{ $t('Please map all imported categories with MyPanel categories to proceed.') }}
</span>
  <!-- Success message -->
  <div v-if="isLoading || successMessage" class="success-message" style="margin-top: 20px;">
    <div v-if="isLoading" class="loader">&nbsp;</div>
      <span v-if="isLoading" class="">
         <i class="fa fa-spinner fa-spin" />  Please wait...
      </span>
     
      <span v-else>
        {{ successMessage }}
      </span>
    </div>
</div>




        

          </div>
        </div>

    </b-alert>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="4"
            lg="3"
            xl="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-xl-0"
          >
            <b-button-group class="w-100">
              <json-excel
                :fetch="fetchAllEntities"
                :fields="exportFields"
                :name="config.title.plural + '.xls'"
                class="w-50"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="w-100 right-radius-reset"
                >
                  Excel
                </b-button>
              </json-excel>
              <json-excel
                :fetch="fetchAllEntities"
                :fields="exportFields"
                :name="config.title.plural + '.csv'"
                :escape-csv="false"
                type="csv"
                class="w-50"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="w-100 left-radius-reset"
                >
                  CSV
                </b-button>
              </json-excel>
            </b-button-group>
          </b-col>
          <!-- Per Page -->
          <b-col
            v-if="!mapView"
            cols="12"
            md="8"
            lg="3"
            xl="3"
            class="d-flex align-items-center justify-content-end mb-1 mb-xl-0"
          >
            <label>{{ $t('Entries') }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              append-to-body
              class="per-page-selector d-inline-block ml-50 mr-lg-50"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="12" lg="12" xl="7">
            <b-row class="d-flex align-items-center justify-content-end">
              <b-col
                :md="showHeadRowButton ? 8 : 12"
                cols="12"
                class="mb-1 mb-md-0"
              >
                <b-form-input
                  v-if="!reportView"
                  :placeholder="$t('Search') + '...'"
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                />
              </b-col>

              <b-col v-if="showHeadRowButton" cols="12" md="4">
                <b-button
                  v-if="config.limited && remainingEntries < 1"
                  :href="eshopUrl + '/mpu-subscriptions'"
                  variant="success"
                  class="w-100"
                  target="_blank"
                >
                  <span class="text-nowrap">{{ $t('Upgrade') }}</span>
                </b-button>
                <b-button
                  v-else-if="!reportView && allowInsert && hasInsertBtn"
                  :to="getCreateLink()"
                  variant="primary"
                  class="w-100"
                  @click="handleAddClick()"
                >
                  <span class="text-nowrap">
                    {{ $t('Add') }} {{ config.title.single }}
                  </span>
                </b-button>
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-else-if="reportView"
                  :text="$t('Download full report')"
                  :disabled="downloadingFile"
                  class="w-100 ml-md-1"
                  right
                  variant="primary"
                >
                  <b-dropdown-item
                    v-for="format in exportFileFormats"
                    :key="format"
                    @click="downloadFile(format)"
                  >
                    {{ format.toUpperCase() }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <div v-if="mapView">
        <GmapMap
          v-if="tableReady"
          ref="refEntitiesMap"
          :center="center"
          :zoom="13"
          map-type-id="roadmap"
        >
          <GmapInfoWindow
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          />
          <GmapMarker
            v-for="(item, index) in entities"
            :key="'marker' + item.id"
            :position="{ lat: item.lat, lng: item.lng }"
            :animation="animation"
            @click="toggleInfoWindow(item, index)"
          />
          <GmapCircle
            v-for="item in entities"
            :key="'circle' + item.id"
            :center="{ lat: item.lat, lng: item.lng }"
            :radius="item.serviceRadius"
            :options="circleOptions"
            :draggable="false"
            :editable="false"
          />
        </GmapMap>
      </div>
      <div v-else>
        <b-table
          v-if="tableReady"
          ref="refEntitiesTable"
          :empty-text="$i18n.t('No matching records found')"
          :items="fetchEntities"
          :fields="columns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative entity-table"
          responsive       
          primary-key="id"
          show-empty
        >
          <!-- Column: name -->
          
          <template #cell(title)="data">
            <b-link
              v-if="allowEdit && !hideEdit"
              :to="getEditLink(data.item.id)"
              class="font-weight-bold d-block"
              @click="handleEntityClick(data.item)"
            >
           
            
              {{ data.item.title }}
            </b-link>
            <span v-else>
              {{ data.item.title }}
            </span>
            <span v-if="data.item.productPricePublishedStatus === 'PRODUCT_SAVED_IN_MY_PANEL'" class="loaderlistitem">&nbsp;</span>
          </template>
          
          <template
            v-for="field in formattedColumns"
            #[`cell(${field.key})`]="data"
          >
         
            <feather-icon
              v-if="field.type == 'boolean'"
              :key="field.key"
              :icon="data.item[field.key] ? 'CheckIcon' : 'XIcon'"
              size="18"
            />
            <b-img
              v-else-if="field.type == 'image' && data.item[field.key]"
              :key="field.key + '_img'"
              :src="data.item[field.key]"
              :width="field.width ? field.width : 100"
              alt=""
              referrer-policy="no-referrer"
              thumbnail
            />
            <b-img
              v-else-if="field.type == 'logo' && data.item[field.key]"
              :key="field.key + '_logo'"
              :src="data.item[field.key]"
              :width="field.width ? field.width : 100"
              alt=""
              rounded="circle"
            />
            
            <avatar-cell
              v-else-if="field.type == 'seller' || field.type == 'supplier'"
              :id="`entity-row-${data.item.id}`"
              :key="field.key + '_seller'"
              :logo="data.item[field.type].logo"
              :url="data.item[field.type].eshopSlug"
              :title="data.item[field.type].title"
              :subtitle="data.item[field.type].perioxh"
              :base-url="field.type"
            />
            <avatar-cell
              v-else-if="field.type == 'partner-sender'"
              :id="`entity-row-${data.item.id}`"
              :key="field.key + '_send'"
              :logo="data.item.senderLogo"
              :url="data.item.senderUrl"
              :title="data.item.sender"
              :subtitle="data.item.senderCompany"
            />
            <avatar-cell
              v-else-if="field.type == 'partner-receiver'"
              :id="`entity-row-${data.item.id}`"
              :key="field.key + '_receive'"
              :logo="data.item.receiverLogo"
              :url="data.item.receiverUrl"
              :title="data.item.receiver"
              :subtitle="data.item.receiverCompany"
            />
            <b-avatar
              v-else-if="field.type == 'status'"
              :id="`entity-row-${data.item.id}`"
              :key="field.key + '_status'"
              :variant="`light-${
                resolveStatusVariantAndIcon(data.item[field.key]).variant
              }`"
              size="32"
            >
              <feather-icon
                :icon="resolveStatusVariantAndIcon(data.item[field.key]).icon"
              />

              <b-tooltip
                v-if="
                  data.item[field.key] === true ||
                  data.item[field.key] === false
                "
                :target="`entity-row-${data.item.id}`"
                placement="top"
              >
                <p class="mb-0">
                  {{
                    data.item[field.key] === true
                      ? $t('Approved')
                      : $t('Pending')
                  }}
                </p>
               
              </b-tooltip>
            </b-avatar>
            
            <span
              v-else-if="field.type == 'translate'"
              :key="field.key + '_translate'"
              :class="{ 'subscription-active': data.item[field.key] === 'ACTIVE' }"
            >
              {{ $t(data.item[field.key]) }}
            </span>
            
            <span
              v-for="(innerItem, index) in data.item[field.key]"
              v-else-if="field.type == 'list'"
              :key="innerItem.id"
            >
              {{
                innerItem.title +
                (index == data.item[field.key].length - 1 ? '' : ', ')
              }}
            </span>
            
            <template v-else-if="field.type == 'date'">
              {{ formatDate(data.item[field.key]) }}
            </template>
            <template v-else-if="field.type == 'daymonth'">
              {{
                formatDate(data.item[field.key], {
                  month: 'long',
                  day: 'numeric',
                })
              }}
            </template>
            <template v-else-if="field.type == 'datetime'">
              {{
                formatDate(data.item[field.key], {
                  dateStyle: 'medium',
                  timeStyle: 'medium',
                  hour12: false,
                })
              }}
            </template>
            <template v-else-if="field.type == 'price'">
              
              {{ formatPrice(data.item[field.key]) }}
             
            </template>
           
          </template>
          
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <template v-if="inlineActions">
             
              <div class="w-100px">
                <b-button
                  v-b-tooltip.hover
                  v-if="detailsView"
                  :title="$t('Details')"
                  :to="getViewLink(data.item.id)"
                  variant="primary"
                  size="sm"
                  class="mr-50 mb-25"
                >
                  <feather-icon icon="FileTextIcon" />
                </b-button>
                
                <b-button
                  v-b-tooltip.hover
                  v-if="allowEdit && !hideEdit"
                  :title="$t('Edit')"
                  :to="getEditLink(data.item.id)"
                  variant="primary"
                  size="sm"
                  class="mr-50 mb-25"
                  @click="handleEntityClick(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  v-b-tooltip.hover
                  v-if="allowEdit && allowDuplicate"
                  :title="$t('Duplicate')"
                  :to="getCopyLink(data.item.id)"
                  variant="secondary"
                  size="sm"
                  class="mr-50 mb-25"
                  @click="handleDuplicateClick(data.item)"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>

                <template v-for="extraAction in extraActions">
                  <b-button
                    v-b-tooltip.hover
                    v-if="extraActionShouldRender(extraAction, data.item)"
                    :key="extraAction.endpoint"
                    :title="$t(extraAction.title)"
                    :disabled="extraAction.disabled"
                    :variant="
                      extraAction.variant ? extraAction.variant : 'info'
                    "
                    :href="
                      extraAction.href
                        ? extraAction.href +
                          (extraAction.appendField
                            ? data.item[extraAction.appendField]
                            : '')
                        : null
                    "
                    size="sm"
                    class="mr-50 mb-25"
                    target="_blank"
                    @click="handleExtraActionClick(data.item, extraAction)"
                  >
                    <feather-icon :icon="extraAction.icon" />
                  </b-button>
                </template>

                <b-button
                  v-b-tooltip.hover
                  v-if="enableDisableToggle"
                  :title="$t(data.item.enabled ? 'Disable' : 'Enable')"
                  :variant="data.item.enabled ? 'success' : 'warning'"
                  :disabled="disableActions"
                  size="sm"
                  class="mr-50 mb-25"
                  @click="toggleEnableDisable(data.item)"
                >
                  <feather-icon
                    :icon="
                      data.item.enabled ? 'ToggleRightIcon' : 'ToggleLeftIcon'
                    "
                  />
                </b-button>

                <b-button
                  v-b-tooltip.hover
                  v-if="allowDelete && !hideDelete"
                  :title="$t('Delete')"
                  variant="danger"
                  size="sm"
                  class="mr-50 mb-25"
                  @click="openDeleteModal(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </template>

            <b-dropdown v-else variant="link" no-caret>
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="detailsView"
                :to="getViewLink(data.item.id)"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">{{ $t('Details') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="allowEdit && !hideEdit"
                :to="getEditLink(data.item.id)"
                @click="handleEntityClick(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="allowEdit && allowDuplicate"
                :to="getCopyLink(data.item.id)"
                @click="handleDuplicateClick(data.item)"
              >
                <feather-icon icon="CopyIcon" />
                <span class="align-middle ml-50">{{ $t('Duplicate') }}</span>
              </b-dropdown-item>

              <template v-for="extraAction in extraActions">
                <b-dropdown-item
                  v-if="extraActionShouldRender(extraAction, data.item)"
                  :key="extraAction.title"
                  :disabled="disableActions"
                  :href="
                    extraAction.href
                      ? extraAction.href +
                        (extraAction.appendField
                          ? data.item[extraAction.appendField]
                          : '')
                      : null
                  "
                  target="_blank"
                  @click="handleExtraActionClick(data.item, extraAction)"
                >
                  <feather-icon :icon="extraAction.icon" />
                  <span class="align-middle ml-50">{{
                    $t(extraAction.title)
                  }}</span>
                </b-dropdown-item>
              </template>

              <b-dropdown-item
                v-if="enableDisableToggle"
                :disabled="disableActions"
                @click="toggleEnableDisable(data.item)"
              >
                <feather-icon
                  :icon="
                    data.item.enabled ? 'ToggleRightIcon' : 'ToggleLeftIcon'
                  "
                />
                <span class="align-middle ml-50">{{
                  $t(data.item.enabled ? 'Disable' : 'Enable')
                }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="allowDelete"
                @click="openDeleteModal(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{
                $t('ShowingEntries', {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  entries: dataMeta.of,
                })
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
          <b-modal v-model="showDeleteModal" title="Επιβεβαίωση διαγραφής" class="delete-modal" centered @ok="handleDeleteClick(itemToDelete)" >
            <p class="delete-modal-content"> Είστε σίγουρος ότι θέλετε να διαγράψετε οριστικά το <strong>{{ itemToDelete&&itemToDelete.title }}</strong>?</p>
          </b-modal>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAlert,
  BImg,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BButtonGroup,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BLink,
  VBTooltip,
  BTooltip,
  BAvatar,
  BModal,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import JsonExcel from 'vue-json-excel'
import Ripple from 'vue-ripple-directive'
import { formatDate, formatPrice } from '@core/utils/filter'
import { getUserRights } from '@/auth/utils'
import EntityHandlerSidebar from '@/layouts/entity/EntityHandlerSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AvatarCell from './AvatarCell.vue'
import { mapMutations } from 'vuex'
import { isSeller} from '@/auth/utils'




export default {
  components: {
    BAlert,
    BImg,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BButtonGroup,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BLink,
    vSelect,
    flatPickr,
    EntityHandlerSidebar,
    JsonExcel,
    BTooltip,
    BAvatar,
    BCardActions,
    AvatarCell,
    BModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        store: null,
        module: 'example',
        endpoint: 'example',
        route: 'example',
        title: {
          single: 'Example',
          plural: 'Examples',
        },
        limited: null,
      }),
    },
    columns: {
      type: Array,
      default() {
        return [
          { key: 'id', label: this.$t('id'), sortable: true },
          { key: 'name', label: this.$t('name'), sortable: true },
          { key: 'description', label: this.$t('description'), sortable: true },
          { key: 'actions', label: this.$t('actions') },
        ]
      },
      required: false,
    },
    mapView: {
      type: Boolean,
      default: false,
      required: false,
    },
    detailsView: {
      type: Boolean,
      default: false,
      required: false,
    },
    reportView: {
      type: Boolean,
      default: false,
      required: false,
    },
    singleEdit: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowDuplicate: {
      type: Boolean,
      default: true,
      required: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideDelete: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasInsertBtn: {
      type: Boolean,
      default: true,
      required: false,
    },
    filters: {
      type: Array,
      default: null,
      required: false,
    },
    filterVals: {
      type: Array,
      default: null,
      required: false,
    },
    customQuery: {
      type: Object,
      default: () => {},
      required: false,
    },
    resultsPerPage: {
      type: Number,
      default: 10,
      required: false,
    },
    formFields: {
      type: Array,
      default() {
        return [
          {
            id: 'name',
            type: 'text',
            label: this.$t('name'),
            required: true,
          },
          {
            id: 'description',
            type: 'textarea',
            label: this.$t('Description'),
          },
        ]
      },
      required: false,
    },
    formattedColumns: {
      type: Array,
      default: null,
      required: false,
    },
    authority: {
      type: String,
      default: '',
      required: false,
    },
    extraActions: {
      type: Array,
      default: null,
      required: false,
    },
    inlineActions: {
      type: Boolean,
      default: false,
      required: false,
    },
    importCsv: {
      type: String,
      default: '',
      required: false,
    },
    sidebarSize: {
      type: String,
      default: 'lg',
      required: false,
    },
    enableDisableToggle: {
      type: Boolean,
      default: false,
      required: false,
    },
    descSort: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    return {
      // Filter
      formatDate,
      formatPrice,
    }
  },
  data() {
    return {
      modalOpen: false,
      uploadedFiles: [],
      mappings: [],
      categories: [],
      subcategories: [], 
      isLoading: false,
      showError: false,
      csvId: null,
      csvName: null,
      unmappedCategories: [],
      successMessage: "",
      item: {
        selected: '',
        subcategory: '',
      },
      uploadresponse: '',
      loading: false,
      allowInsert: false,
      uploadSuccess: false,
      allowEdit: false,
      allowDelete: false,
      downloadingFile: false,
      isEntityHandlerSidebarActive: false,
      isEntityDuplicate: false,
      blankEntity: null,
      entity: { id: null },
      perPage: 10,
      totalRows: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: !this.descSort,
      entities: [],
      tableReady: false,
      updateFilterDebounce: null,
      disableActions: false,
      center: {
        lat: 37.98400922344468,
        lng: 23.68682929788152,
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      animation: 2,
      showDeleteModal: false,
      itemToDelete: null,
      //loading: false,
    }


  },
  computed: {
    eshopUrl() {
      return process.env.VUE_APP_SHOP_URL
    },
    isSeller() {
      return isSeller()
    },
    canImport() {
      // Check if all mappings have a selected category
      return this.mappings.every(item => item.selected !== null);
    },
    remainingEntries() {
      return this.config.limited ? this.config.limited - this.totalRows : null
    },
    extraParams() {
      const params = []
      this.filters.forEach((filter) => {
        params.push(filter.name)
      })
      return params
    },
    exportFields() {
      const jsonFields = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const [, obj] of Object.entries(this.columns)) {
        if (obj.key !== 'actions') {
          jsonFields[obj.label] = obj.key
        }
      }
      return jsonFields
      
    },
    dataMeta() {
      const localItemsCount = this.$refs.refEntitiesTable
        ? this.$refs.refEntitiesTable.localItems.length
        : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRows,
      }
    },

    exportFileFormats() {
      return ['pdf', 'html', 'xlsx', 'csv', 'xml']
    },

    getBooleanOptions() {
      return [
        { id: null, name: this.$t('All') },
        { id: '1', name: this.$t('Yes') },
        { id: '0', name: this.$t('No') },
      ]
    },

    showHeadRowButton() {
      return (
        (this.config.limited && this.remainingEntries < 1) ||
        this.reportView ||
        (this.allowInsert && this.hasInsertBtn)
      )
    },

    circleOptions() {
      return {
        strokeColor: '#A7844E',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#A7844E',
        fillOpacity: 0.35,
      }
    },
  },
  created() {
    const rights = getUserRights()

    this.allowInsert = rights.includes(`${this.authority}_Create`)
    if(this.authority === 'Users_Payment'){
      this.allowInsert = false;
    }
    this.allowEdit = rights.includes(`${this.authority}_Edit`)
    this.allowDelete = rights.includes(`${this.authority}_Delete`)

    this.perPage = this.resultsPerPage

    if (this.filters) {
      this.filters.forEach((filter, index) => {
        if (filter.value) {
          this.filterVals[index] = filter.value
        }
      })
    }
    // Register module
    if (!this.$store.hasModule(this.config.module)) {
      this.$store.registerModule(this.config.module, this.config.store)
      this.$store.commit(`${this.config.module}/setConfig`, this.config)
    }
  },
  mounted() {
    this.fetchCategories();
    this.initBlankEntity()
    if(this.$store.getters.getProductCode){
      this.setvalue()
      this.setProductCode(null)
    }
    this.$watch('currentPage', this.refetchData)
    this.$watch('perPage', this.refetchData)
    this.$watch('searchQuery', this.refetchData)
    if (this.mapView) {
      this.fetchAllEntities()
    }
    this.tableReady = true
  },
  beforeDestroy() {
    // if (this.$store.hasModule(this.config.module)) {
    //   this.$store.unregisterModule(this.config.module)
    // }
  },
  methods: {
    ...mapMutations(['setProductCode']),
    setvalue(){
      this.searchQuery=this.$store.getters.getProductCode
    },
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      // Optionally, clear uploaded files when closing modal
      this.uploadedFiles = [];
    },
   handleFileChange(event) {
      this.uploadedFiles = Array.from(event.target.files);
    },


   
    async uploadFiles() {
      if (this.uploadedFiles.length === 0) {
        alert('Please select a file to upload.');
        return;
      }

      const formData = new FormData();
      this.uploadedFiles.forEach(file => {
        formData.append('file', file);
      });

      this.loading = true;

      try {
        const token = localStorage.getItem('access_token');
        //console.log(token)
       // const response = await axios.post('http://3.77.64.187:9093/products/upload', formData, {
       // const response = await axios.post('http://3.77.64.187:9093/products/upload/csv', formData, {
        const response = await axios.post(`${this.config.endpoint}/upload/csv`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          },
        });
        console.log(this.filters)

       // console.log('Upload successful:', response.data);
        this.csvId = response.data.csvId;
        this.csvName = response.data.csvName;
       // this.showToast({
          //title: this.$t('File Upload successfully!'),
         // message: this.$t('File uploaded successfully!'),
        //  variant: 'success',
       // });
        //this.uploadresponse = response?.data?.message;
        //this.closeModal();
        //this.uploadresponse = response?.data?.totalNoOfProductsAdded;
        //this.uploadresponse = response?.data?.message;

        //this.uploadresponse = response?.data?.totalNoOfProductsAdded + ' ' + response?.data?.message  + '  ' +response?.data?.existingProductNewSellerCount +' ' +  ' already existing products.' ;
        this.unmappedCategories = response?.data?.unmappedCategories || [];
        this.uploadSuccess = true;
          if (response?.data?.existingProductNewSellerCount > 0) {
            this.uploadresponse = response?.data?.newProductCount + ' ' + response?.data?.message  + '  ' +response?.data?.existingProductNewSellerCount +' ' +  ' already existing products.' ;
          }else{
            if(response.data =="There are no products to be saved!"){
              this.uploadresponse = "There are no products to be saved!";
            }else{
              this.uploadresponse = response?.data?.newProductCount + ' ' + response?.data?.message;
            }
            
          }
        
       /* setTimeout(() => {
          this.closeModal();
          location.reload();
        }, 7000);*/
        const listIds = response?.data?.productCodes;
        if(response.data.productCodes && response.data.productCodes.length > 0){
          this.publishBulkProducts(token, listIds);
        }
         const unmappedCategories = response?.data?.unmappedCategories || [];
         this.mappings = unmappedCategories.map(category => ({
          id: category.id,
           input: category.categoryString, 
           selected: false 
         }));
         const category = this.filters.find(item => item.name === "category")
         this.categories = category.options.map((item) => ({
          id: item.id,
          label: item.title
         }))
        //console.log("newcat", this.mappings);
        //console.log("oldcat", this.categories);
      } catch (error) {
       // console.error('Upload failed:', error.response.data.message);
        this.showToast({
          title: this.$t('This CSV Type is not predefined. Cannot process this CSV type.'),
          message: this.$t('File upload failed. Please try again.'),
          variant: 'danger',
        });
        this.uploadresponse = error.response.data.message;
        
        // setTimeout(() => {
        //   this.closeModal();
        //   location.reload();
        // }, 4000);

      } finally {
        this.loading = false;
        
      }
      
    },


    async deleteAndCloseModal() {
    try {
        // Make the DELETE request using csvId
        const token = localStorage.getItem('accessToken');
       // await fetch(`http://3.77.64.187:9093/products/temporary-files/${this.csvId}`, {
        await fetch(`${this.config.endpoint}/temporary-files/${this.csvId}`, {
            method: 'DELETE',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        this.closeModal();
        //location.reload();
    } catch (error) {
        console.error('Error deleting temporary files:', error);
    }
},



  //   addMapping() {
  //   this.mappings.push({ input: '', selected: '' });
  // },

  // removeMapping(index) {
  //   this.mappings.splice(index, 1);
  // },

  async fetchCategories() {
      // Fetch categories from your API and set this.categories
      const category = this.filters.find(item => item.name === "category");
      this.categories = category.options.map(item => ({
        id: item.id,
        label: item.title,
      }));
    },

    // Fetch subcategories based on categoryId for a specific mapping
    async fetchSubcategories(categoryId, mappingIndex) {
      if (!categoryId) {
        this.$set(this.subcategories, mappingIndex, []);
        return;
      }

      try {
        
        if(`${this.config.endpoint}` ==='products'){
          this.$Catendpoint = "categories";
        }
        console.log(this.$Catendpoint);
       // const response = await axios.get(`http://3.77.64.187:9093/categories/list/all-sub-categories?categoryId=${categoryId}`);
       const response = await axios.get(`${this.$Catendpoint}/list/all-sub-categories?categoryId=${categoryId}`);
       this.$set(this.subcategories, mappingIndex, response.data); // Store subcategories by index
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    },
    
    // Example method to handle mapping creation from new categories
    handleNewCategories(response) {
      const unmappedCategories = response?.data?.unmappedCategories || [];
      this.mappings = unmappedCategories.map(category => ({
        input: category.categoryString, 
        selected: null,
        subcategory: null // Add subcategory to the mapping object
      }));
      
      this.fetchCategories(); // Fetch categories after initializing mappings
    },

    deselectMapping(index) {
      this.$set(this.mappings, index, {
        ...this.mappings[index], // Spread existing properties
        selected: null,          // Reset selected category
        subcategory: null        // Reset subcategory
      });
      this.$set(this.subcategories, index, []); // Clear the subcategories for this mapping
    },


    async importSelectedCategories(token) {
  // Prepare the data in the required format
  this.showError = false; // Reset error state
  const hasEmptyCategory = this.mappings.some(item => !item.selected);

  if (hasEmptyCategory) {
    this.showError = true; // Show error if any category is empty
    return; // Prevent further execution
  }
  
  this.isLoading = true;

  const categoryMap = this.mappings.map((item, index) => {
    const categoryData = {
      source: {
        id: item.id || 0, // Default ID to 0 if not present
        categoryString: item.input || "" // Default to an empty string if no input
      },
      category: {
        id: item.selected || 0, // Default ID to 0 if not selected
        category: this.categories.find(category => category.id === item.selected)?.label || "" // Default to empty string if not found
      },
    };
    
    if (item.subcategory) {
      categoryData.subCategory = {
        id: item.subcategory,
        category: this.subcategories[index]?.find(sub => sub.id === item.subcategory)?.title || ""
      };
    }
    
    return categoryData;
  });

  try {
    const csvId = response?.data?.csvId;
    const csvName = response?.data?.csvName;
    
   // const response = await axios.put(
    //  `http://3.77.64.187:9093/products/csv/${this.csvId}`,
      const response = await axios.put(
        `${this.config.endpoint}/csv/${this.csvId}`,
      {
        csvId: this.csvId,
        csvName: this.csvName,
        categoryMap: categoryMap,
        // totalPages: Math.ceil(categoryMap.length / 10) // Uncomment if needed for pagination
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }
    );
    const listIds = response?.data?.productCodes;
        if(response.data.productCodes && response.data.productCodes.length > 0){
          this.publishBulkProducts(token, listIds);
        }
    console.log('Import successful:', response.data);
    const newProductCount = response.data.newProductCount; // Extract newProductCount
    const message = response.data.message; // Extract message
    const publishableProductsAdded = response.data.publishableProductsAdded; 
   // this.successMessage = response.data//"Categories Mapped Successfully!";
   if(response.data =="There are no products to be saved!"){
    this.successMessage = "There are no products to be saved!"
   }else{
    //this.successMessage = `${publishableProductsAdded} products are being published to the e-shop.`;
    this.successMessage = `${publishableProductsAdded} products imported successfully.`;
   }
    
    setTimeout(() => {
      this.successMessage = "";
      this.closeModal();
      location.reload();
     /* if (this.successMessage === "There are no products to be saved!") {
        this.closeModal();  // Close the modal
        location.reload();   // Reload the page
      }*/
    }, 5000);
    
  } catch (error) {
    console.error('Error during import:', error);
    this.successMessage = "Please map all imported categories with MyPanel categories to proceed.";
  } finally {
    this.isLoading = false;
  }
},


async publishBulkProducts(token, listIds) {
    try {
        // Set the loading indicator to true before starting
        this.isLoading = true;

        // Make the POST request using axios with await

        //
        //const response = await axios.post(
        //    'http://3.77.64.187:9093/products/eshop/publish/list',
        const response = await axios.post(
            `${this.config.endpoint}/eshop/publish/list`,
            listIds,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
        );

        // Process the response data
        console.log('Publish bulk products response:', response.data);
        const { updated_count, message, created_count } = response.data;
        const successMessage = `${updated_count} ${message} successfully!`;
        localStorage.setItem('successMessage', successMessage);
        this.successMessage = successMessage;
        setTimeout(() => {
            this.closeModal();
            location.reload();
        }, 5000);

    } catch (error) {
        // Error handling
       // console.error('Error during publishBulkProducts:', error);
       // const errorMessage = "There was an error publishing the products.";
        //localStorage.setItem('successMessage', errorMessage);

        setTimeout(() => {
            this.closeModal();
           location.reload();
        }, 7000); // 7 seconds delay after error

    } finally {
        // Hide the loading indicator in the finally block
        this.isLoading = false;
    }
},

  
    initBlankEntity() {
      this.formFields.forEach((field) => {
        if (field.initVal) {
          this.entity[field.id] = field.initVal
        } else if (field.type === 'select' || field.type === 'selectmultiple') {
          this.entity[field.id] = null
        } else if (
          field.type === 'checkbox' ||
          field.type === 'checkboxgroup'
        ) {
          this.entity[field.id] = false
        } else {
          this.entity[field.id] = ''
        }
      })
      this.blankEntity = JSON.parse(JSON.stringify(this.entity))
    },

    getQueryParams() {
      const queryParams = {
        q: this.searchQuery,
        page: this.currentPage - 1,
        size: this.perPage,
        sort: `${this.sortBy},${this.isSortDirDesc ? 'asc' : 'desc'}`,
      }

      if (this.filters) {
        this.extraParams.forEach((value, index) => {
          if (this.filterVals[index]) {
            queryParams[value] = this.filterVals[index]
          }
        })
      }

      if (this.customQuery) {
        Object.keys(this.customQuery).forEach((key) => {
          queryParams[key] = this.customQuery[key]
        })
      }

      return queryParams
    },

    refetchData() {
      if (this.mapView) {
        this.fetchAllEntities()
      } else {
        this.$refs.refEntitiesTable.refresh()
      }
      this.disableActions = false
    },

    fetchEntities() {
      return this.$store
        .dispatch(`${this.config.endpoint}/getEntities`, this.getQueryParams())
        .then((response) => {
          const items = response.data.content
          this.totalRows = response.data.totalElements
          if(this.config.endpoint === 'products')
            localStorage.setItem('productsAdded', response.data.totalElements)
          this.entities = items || []
          return this.entities
        })
        .catch(() => {
          this.showToast({
            title: `Error fetching ${this.config.title.plural} list`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    },

    fetchAllEntities() {
      const params = {
        ...this.getQueryParams(),
        size: null,
      }

      return this.$store
        .dispatch(`${this.config.endpoint}/getEntities`, params)
        .then((response) => {
          const items = response.data.content
          this.totalRows = response.data.totalElements
          this.entities = items || []
          return this.entities
        })
        .catch(() => {
          this.showToast({
            title: `Error fetching ${this.config.title.plural} list`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    },
    resolveStatusVariantAndIcon(status) {
      if (status === true || status === 'ACTIVE') {
        return { variant: 'success', icon: 'CheckCircleIcon' }
      }
      if (status === 'DECLINED') return { variant: 'danger', icon: 'XIcon' }
      if (status === 'DELETED') {
        return { variant: 'danger', icon: 'Trash2Icon' }
      }

      return { variant: 'warning', icon: 'InfoIcon' }
    },

    getViewLink(itemId) {
      return { name: `${this.config.route}-view`, params: { id: itemId } }
    },

    getCreateLink() {
      if (this.config.insertRoute) {
        return this.config.insertRoute
      }
      return this.singleEdit ? { name: `${this.config.route}-edit` } : '#'
    },

    getEditLink(itemId) {
      return this.singleEdit
        ? { name: `${this.config.route}-edit`, params: { id: itemId } }
        : '#'
    },

    getCopyLink(itemId) {
      return this.singleEdit
        ? { name: `${this.config.route}-edit`, params: { duplicate: itemId } }
        : '#'
    },

    getOriginDuplicateLink(itemId) {
      return this.singleEdit
        ? {
            name: `${this.config.route}-edit`,
            params: { duplicate: itemId, keepOrigin: true },
          }
        : '#'
    },

    extraActionShouldRender(action, item) {
      if (
        // If no authority validation is given or the validation passes continue
        // eslint-disable-next-line no-prototype-builtins
        (!action.hasOwnProperty('authorityValidation') ||
          action.authorityValidation) &&
        // If no render condition is given or the key matches the desired value continue
        (!action.renderCondition ||
          item[action.renderCondition.key] === action.renderCondition.value)
      ) {
        return true
      }

      return false
    },

    showToast(toastProps) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: toastProps,
      })
    },

    updateFilterValues(newVal, index) {
      if (this.updateFilterDebounce) return

      this.filterVals[index] = newVal

      this.updateFilterDebounce = setTimeout(() => {
        this.refetchData()
        this.updateFilterDebounce = null
      }, 100)
    },

    clearEntityData() {
      this.entity = JSON.parse(JSON.stringify(this.blankEntity))
    },

    handleAddClick() {
      if (!this.singleEdit) {
        this.isEntityHandlerSidebarActive = true
      }
    },

    handleEntityClick(entityData) {
      if (!this.singleEdit) {
        this.isEntityDuplicate = false
        this.entity = JSON.parse(JSON.stringify(entityData))
        this.isEntityHandlerSidebarActive = true
      }
    },

    handleDuplicateClick(entityData) {
      if (!this.singleEdit) {
        this.isEntityDuplicate = true
        this.entity = JSON.parse(JSON.stringify(entityData))
        this.isEntityHandlerSidebarActive = true
      }
    },

    handleDeleteClick(id) {
      this.$store
        .dispatch(`${this.config.endpoint}/deleteEntity`, id)
        .then(() => {
          this.refetchData()
          this.showDeleteModal = false;
        })
        .catch(() => {
          this.showToast({
            title:
              'The entity could not be deleted! Check the foreign key constraints first.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
          this.showDeleteModal = false;
        })
    },
    openDeleteModal(item) {
      // Open the delete confirmation modal
      this.itemToDelete = item;
      this.showDeleteModal = true;
    },

    async handleExtraActionClick(item, action) {
      if (!action.href) {
        this.disableActions = true
        if (action.callback) {
          if (action.callback === 'getEditLink') {
            this.$router.push(this.getEditLink(item.id))
          } else if (action.callback === 'relationDuplicate') {
            this.$router.push(this.getOriginDuplicateLink(item.id))
          } else if (action.callback === 'submitSellRequest') {
            await axios.get(
              `${this.config.route}/suppliers/sell-request/${item.id}`
            )
            this.$router.push(this.getEditLink(item.id))
          } else if (action.callback === 'updateStockPrice') {
            await axios.put(`${this.config.endpoint}/${action.endpoint}`, {
              id: item.id,
              regularPrice: item.regularPrice,
              quantity: item.quantity,
            })
          } else if (action.callback.includes('updatePartnerStatus')) {
            const status = action.callback.split('_')[1]

            const data = { ...item }
            data.status = status
            axios
              .put(this.config.actionsEndpoint, data)
              .then(() => {
                this.refetchData()
              })
              .catch((error) => {
                this.handleSaveError(error)
              })
          }
        } else {
          await axios.get(
            `${this.config.endpoint}/${action.endpoint}/${item.id}`
          ).then((response) => {

          }).catch((error) => {
            if (action.endpoint === 'partners/request') {
              this.showToast({
            title: 'Cannot send partner request!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response.data.params[0]
          })
            } else if (action.endpoint === 'lead') {
              this.showToast({
            title: 'Cannot add Lead!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response.data.params[0]
          })
            } else if (action.endpoint === 'users') {
              this.showToast({
            title: 'Cannot add User!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response.data.params[0]
          })
            } else 
              this.handleSaveError(error)
          })
        }
        this.refetchData()
      }
    },

    async toggleEnableDisable(item) {
      this.disableActions = true
      await axios.put(`${this.config.endpoint}/enabled`, {
        id: item.id,
        enabled: !item.enabled,
      })

      this.refetchData()
    },

    downloadFile(format) {
      this.downloadingFile = true

      const payload = { format, params: this.getQueryParams() }

      this.$store
        .dispatch(`${this.config.endpoint}/downloadReport`, payload)
        .then((response) => {
          const fileName = `${
            this.config.title.single
          }_${new Date().toISOString()}.${format}`
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          this.downloadingFile = false
        })
    },

    addEntity(data) {
      this.saveEntity('add', data)
    },

    updateEntity(data) {
      this.saveEntity('update', data)
    },

    saveEntity(saveType, data) {
      this.$store
        .dispatch(`${this.config.endpoint}/${saveType}Entity`, data)
        .then(() => {
          this.handleEntitySave()
        })
        .catch((error) => {
          this.handleSaveError(error)
        })
    },

    handleEntitySave() {
      this.isEntityHandlerSidebarActive = false
      this.refetchData()
    },

    handleSaveError(error) {
      this.showToast({
        title: this.$t('Save Failure'),
        icon: 'AlertTriangleIcon',
        variant: 'danger',
        text: error.message,
      })
    },
    toggleInfoWindow(item, idx) {
      this.infoWindowPos = { lat: item.lat, lng: item.lng }
      this.infoOptions.content = item.title

      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-modal-content{
  font-size: 16px; /* Adjust the font size as needed */
}
@media (min-width: 1024px) {
  .entity-table {
    overflow-x: visible;
    overflow-y: visible;
  }
}

.per-page-selector {
  width: 90px;
}

.flat-pickr-inherit {
  background-color: inherit;
}

.flat-pickr-clear {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 1.2;
  cursor: pointer;
}

.right-radius-reset {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.left-radius-reset {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0 !important;
}
.bclor{
  color:blue;
  text-decoration:underline;
  padding-top:12px;
  display:block;
}
.csvfile{
  float: right;
  width: 200px !important;
  margin-right: 12px;
  margin-top: -9px;

}

/* Modal styles */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1050; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

#csvModel .modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.fileUploads, .cls{
  /*width:80px;*/
   float: right;
}
.browsetext label{
  color:#222;
}
.browsetext, .browsetext label{
  text-align:center;
  padding:30px;
  cursor:pointer;
}

.browsetext .b-avatar.badge-secondary{
  background:#A7844E !important;
  margin-bottom:20px;
}

.clear.cls.btn.btn-primary{
  background:#fff !important;
  color:#87909F !important;
  margin-right:10px;
  padding: 0.726rem 1rem !important;
}
.fileUploads{
  padding: 0.726rem 1rem !important;
}
.close {
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  
  border-radius: 0.358rem;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


.success-message {
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}






.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 20px auto;
  text-align: center;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.delete-icon{
  margin-top:17px;
}
.catmap{
  width:100%;
}
.modal-content ul li{
  list-style: none !important;
  color: #222 !important;
  text-align: center !important;
  margin-bottom: 20px !important;
}

.modal-content ul li span.filenam{
  font-size: 17px !important;
  color: #A7844E !important;
}
.modal-content ul li span.filesize{
  font-size: 12px !important;
  color: #222 !important;
  font-weight: 400 !important;
}
.mapping-section .input-box{
  padding: 4px;
  border: 2px solid #a7844e;
  border-radius: 5px;
  color:#a7844e;
}

.mapping-section .dropdown{
  padding: 5px;
  border: 2px solid #a7844e;
  border-radius: 5px;
  background: #fff;
  color:#a7844e;

}

.mapping-section label{
  color:#a7844e;
  font-size:11px;
}
.mapping-section h2{
  color:#a7844e;
}
html[lang="el"][dir="ltr"] .csvfile.btn{
  width:300px !important;

}

.loaderlistitem {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  /*margin:15px auto;*/
  margin-top:0px;
  position: absolute;
  left:45%;
  background: #FFF;
 /* box-shadow: -24px 0 #FFF, 24px 0 #FFF;*/
  box-sizing: border-box;
  animation: shadowPulse 1s linear infinite;
  margin-top: -17px;
  background: rgba(0, 0, 0, 0.5);
}

@keyframes shadowPulse {
  33% {
    background: #FFF;
    box-shadow: -24px 0 #A7844E, 24px 0 #FFF;
  }
  66% {
    background: #A7844E;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  }
  100% {
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #A7844E;
  }
}


</style>
